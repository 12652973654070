import styled from "styled-components";

export const StyleLayout = styled.div`
  & a {
    text-decoration: none;
    border: 1px solid #18f;
    borderradius: 5px;
    padding: 6px 5px;
    minwidth: 80px;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    height: 40px;
    lineheight: 40px;
    // color: #111;
    color: #18f;
    textdecoration: none;
    margin: 10px auto;
    display: block;
  }

  .card-body {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
    /* color: black; */
    border: 2px solid black;
    border-radius: 5px;
    
  }
  
  & .parent-mail {
    display:flex;
    justify-content:space-between;
    }
    & .parent-container {
      display:flex;
      flex-wrap:wrap;
      gap:10px;
      justify-content:space-around;
    }
& .parent-card {
  width: 400px;
}
 
`;
