import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import OrderTotalBox from "../../../Components/OrderTotalBox/OrderTotalBox";
import { Link, useParams } from "react-router-dom";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import { domainList } from "../../../utility/constant";
import { LeftOutlined } from '@ant-design/icons';

const DomainsMailCount = () => {
    const [estaFolderCount, setEstaFolderCount] = useState([]);
    const [turkiyeFolderCount, setTurkiyeFolderCount] = useState([]);
    const [sriLankaFolderCount, setSriLankaFolderCount] = useState([]);
    const [uKeTAFolderCount, setUKeTAFolderCount] = useState([]);
    const [canadaFolderCount, setCanadaFolderCount] = useState([]);
    const [newZealandFolderCount, setNewZealandFolderCount] = useState([]);
    const [cubaArrivalFolderCount, setCubaArrivalFolderCount] = useState([]);
    const [japanFolderCount, setJapanFolderCount] = useState([]);
    const [arubaFolderCount, setArubaFolderCount] = useState([]);
    const [cambodiaEvisaFolderCount, setCambodiaEvisaFolderCount] = useState([]);
    const [cambodiaEarrivalFolderCount, setCambodiaEarrivalFolderCount] = useState([]);
    const [malaysiaArrivalCount, setMalaysiaArrivalCount] = useState([]);
    const [indonesiaCount, setIndonesiaCount] = useState([]);
    const [philippinesCount, setPhilippinesCount] = useState([]);
    const [singaporeCount, setSingaporeCount] = useState([]);
    const [indonesiaEvoaCount, setIndonesiaEvoaCount] = useState([]);
    const [ukCount, setUkCount] = useState([]);
    const [vietnam, setVietnamCount] = useState([]);
    const [egyptCount, setEgyptCount] = useState([]);
    const [koreaCount, setKoreaCount] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [domainData, setDomainData] = useState([]);
    const [mailCountResponse, setMailCountResponse] = useState([]);
    const { id } = useParams();

    const NewOrderscount = useSelector((state) => state?.order?.NewOrderscount);

    const estaMailURL = "https://estaportal.online/imapApiUnreadMailCountSubFolder.php";
    const srilankaMailURL = "https://srilanka-eta.com/admin/imapApiUnreadMailCount.php";
    const canadaMailURL = "https://canada-eta.online/imapApiUnreadMailCountSubFolder.php";
    const egyptUnreadMailCountURL = "https://egypt-portal.site/imapApiUnreadMailCountSubFolder.php";
    const turkiyeMailURL = "https://turkiye-evisa.online/imapApiUnreadMailCountSubFolder.php";
    const vietnamURL = "https://vietnamportal.online/imapApiUnreadMailCountSubFolder.php";
    const koreaURL = "https://korea-portal.online/imapApiUnreadMailCountSubFolder.php";

    let FinalData = [];

    useEffect(() => {
        const result = domainList?.filter((item) => item?.id == id);
        setDomainData(result);
        setIsLoading(true);
        axios
            .get(result[0]?.mailUrl)
            .then((response) => {
                setIsLoading(false);
                let responseArr = [
                    {
                        loginUrl: result[0]?.loginUrl,
                        // gateway: "Completed Folder",
                        unread_standard_mail_count: response?.data?.unread_standard_mail_count || 0,
                        unread_priority_mail_count: response?.data?.unread_priority_mail_count || 0,
                        // unread_future_mail_count: response?.data?.unread_future_mail_count || 0,
                        total_unread_mail_count: response?.data?.total_unread_mail_count || 0,
                        total_standard_receipt: response?.data?.total_standard_receipt || 0,
                        total_priority_receipt: response?.data?.total_priority_receipt || 0,
                        total_receipt: response?.data?.total_receipt || 0,
                    },
                    // {
                    //     loginUrl: result[0]?.loginUrl,
                    //     gateway: "Magic Pay",
                    //     unread_standard_mail_count: response?.data?.unread_magicpay_standard_mail_count || 0,
                    //     unread_priority_mail_count: response?.data?.unread_magicpay_priority_mail_count || 0,
                    //     // unread_future_mail_count: response?.data?.unread_magicpay_future_mail_count || 0,
                    //     total_unread_mail_count: response?.data?.total_magic_pay_count || 0
                    // },
                ]
                setMailCountResponse(responseArr);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    setTimeout(function () {
        window.location.reload();
    }, 60000 * 5);

    const handleClick = () => {
        window.location.reload();
    }

    const tableData = mailCountResponse;

    const columns = [
        {
            name: "#",
            selector: (row) => row.id,
            sortable: true,
            style: {
                paddingTop: "20px",
            },
        },

        {
            name: "Total Orders Completed Folder",
            selector: (row) => row?.total_unread_mail_count,
            sortable: true
        },

        // {
        //     name: "Gateway",
        //     selector: (row) => row?.gateway,
        //     sortable: true,
        // },
        {
            name: "Standard Mail Count",
            selector: (row) => row?.unread_standard_mail_count,
            sortable: true,
        },
        {
            name: "Priority Mail Count",
            selector: (row) => row?.unread_priority_mail_count,
            sortable: true,
        },
        // {
        //     name: "Future Mail Count",
        //     selector: (row) => row?.unread_future_mail_count,
        //     sortable: true,
        // },
        
    ];

    const columns_receipt = [
        {
            name: "#",
            selector: (row) => row.id,
            sortable: true,
            style: {
                paddingTop: "20px",
            },
        },

        {
            name: "Total Orders Receipt",
            selector: (row) => row?.total_receipt,
            sortable: true
        },
        {
            name: "Standard Receipt",
            selector: (row) => row?.total_standard_receipt,
            sortable: true,
        },
        {
            name: "Priority Receipt",
            selector: (row) => row?.total_priority_receipt,
            sortable: true,
        },
        
    ];

    return (
        <StyledContainer>
            <StyledPageTitle>
                <h1><Link to="/total-domains" className="back-btn"> <LeftOutlined />Back</Link> Total Mail Count</h1>
                <button type="button" className="btn btn-success" onClick={handleClick}>Refresh</button>
            </StyledPageTitle>
            <form>
                <StyledOrderBlock>
                    <div className="table-block table-responsive">
                        <ReactDataTable
                            data={tableData}
                            customColumns={columns}
                            // setPage={setPage}
                            // setLimit={setLimit}
                            orderName="orderlist"
                            section="domain_mail_count"
                            isLoadingData={isLoading}
                            domainName={domainData[0]?.domainName || ""}
                        />
                        <ReactDataTable
                            data={tableData}
                            customColumns={columns_receipt}
                            // setPage={setPage}
                            // setLimit={setLimit}
                            orderName="orderlist"
                            section="domain_mail_count"
                            isLoadingData={isLoading}
                            hideHeading = {true}
                        />
                        {/* <OrderTotalBox data={tableData} /> */}
                    </div>
                </StyledOrderBlock>
            </form>
        </StyledContainer>
    );
};

export default DomainsMailCount;
