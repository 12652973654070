import React from 'react'
import LandingCard from '../../../Components/LandingCard/LandingCard';
import "./DomainsTotalMails.css";
import {domainList} from "../../../utility/constant";

const DomainsTotalMails = () => {

    return (
        <div className='container'>
            <h1 className='text-center'>Domain List</h1>
            <div className='parent-domain'>
            {
                domainList?.map((item, i) =>
                    <LandingCard landingSiteName={item?.domainName} siteId={item?.id} imagePath={item?.imageUrl} key={i} />
                )
            }

            </div>
        </div>
    )
}

export default DomainsTotalMails