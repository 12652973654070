import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { StyledDataTable, SearchField } from "./style";
const ReactDataTable = ({ data, customData, customColumns, setButtonData, setDomainList, section, isLoadingData, domainName, hideHeading }) => {
  const [filterData, setFilterData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const isLoading = useSelector((state) => state?.order?.domainLoading);
  let columns;
  let Data = [];

  useEffect(() => {
    setFilterData(data)
  }, [data])

  if (
    data &&
    !customData &&
    section == "new_order_count" &&
    typeof data !== "undefined" &&
    typeof data !== null &&
    data?.length !== 0
  ) {
    Data = filterData?.map((item, index) => {
      return {
        id: index + 1,
        count: item?.count ? item?.count : 0,
        domain: item?.domain,
        loginUrl: item?.loginUrl,
        mailCount: item?.mailCount ? item?.mailCount : 0,
        priorityOrdersCount: item?.priorityOrdersCount ? item?.priorityOrdersCount : 0,
      };
    });
  } else if (data &&
    !customData &&
    section == "total_mail_count" &&
    typeof data !== "undefined" &&
    typeof data !== null &&
    data?.length !== 0) {
    Data = filterData?.map((item, index) => {
      return {
        id: index + 1,
        domain: item?.domain,
        loginUrl: item?.loginUrl,
        unread_standard_mail_count: item?.mailCountData?.unread_standard_mail_count ? item?.mailCountData?.unread_standard_mail_count : 0,
        unread_priority_mail_count: item?.mailCountData?.unread_priority_mail_count ? item?.mailCountData?.unread_priority_mail_count : 0,
        unread_future_mail_count: item?.mailCountData?.unread_future_mail_count ? item?.mailCountData?.unread_future_mail_count : 0,
        unread_mail_count: item?.mailCountData?.unread_mail_count ? item?.mailCountData?.unread_mail_count : 0,
        total_unread_mail_count: item?.mailCountData?.total_unread_mail_count ? item?.mailCountData?.total_unread_mail_count : 0,
      };
    });
  } else if (data &&
    !customData &&
    section == "domain_mail_count" &&
    typeof data !== "undefined" &&
    typeof data !== null &&
    data?.length !== 0) {
    Data = filterData?.map((item, index) => {
      return {
        id: index + 1,
        gateway: item?.gateway,
        domain: item?.domain,
        loginUrl: item?.loginUrl,
        unread_standard_mail_count: item?.unread_standard_mail_count ? item?.unread_standard_mail_count : 0,
        unread_priority_mail_count: item?.unread_priority_mail_count ? item?.unread_priority_mail_count : 0,
        // unread_future_mail_count: item?.unread_future_mail_count ? item?.unread_future_mail_count : 0,
        total_unread_mail_count: item?.total_unread_mail_count ? item?.total_unread_mail_count : 0,
        total_standard_receipt: item?.total_standard_receipt ? item?.total_standard_receipt : 0,
        total_priority_receipt: item?.total_priority_receipt ? item?.total_priority_receipt : 0,
        total_receipt: item?.total_receipt ? item?.total_receipt : 0,
      };
    });
  }


  const handleSearch = (searchContent) => {
    const searchResult = data?.filter((item) => item?.domain?.toLowerCase()?.includes(searchContent.toLowerCase()));
    // setDomainList(searchResult);
    setFilterData(searchResult);
    setSearchText(searchContent);
  }
  if (!customColumns) {
    columns = [
      {
        name: "S.No.",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "New Orders Count",
        selector: (row) => row.count,
        sortable: true,
      },
      {
        name: "Mail Count",
        selector: (row) => row.mailCount,
        sortable: true,
      },
      {
        name: "Login url",
        sortable: true,
        selector: (row) =>
          data?.length > 0 ? (
            <span>
              <a
                href={row?.loginUrl}
                target="_blank"
                style={{
                  border: "1px solid #18f",
                  borderRadius: "5px",
                  padding: " 0 5px",
                  minWidth: "80px",
                  display: "inline-block",
                  textAlign: "center",
                  height: "40px",
                  lineHeight: "40px",
                  color: "#111",
                  margin: "5px",
                  textDecoration: "none",
                }}
                className="blue-border"
              >
                Click here
              </a>
            </span>
          ) : null,
      },
    ];
  } else {
    columns = customColumns;
  }

  return (
    <>
      <div className="text-center mb-3 pb-3">
        {
          (hideHeading == "" || typeof hideHeading == "undefined") && (
            <h6
              className="text-primary text-uppercase"
              style={{ letterSpacing: "5px" }}
            >
              Master Admin Template
            </h6>
          )
        }

        {section == "new_order_count" && (
          <h1>New Orders Count</h1>
        )}

        {section == "total_mail_count" && (
          <h1>Total Mail Count</h1>
        )}

        {(domainName != "" && domainName != undefined) && (
          <h2>{domainName}</h2>
        )}
      </div>
      {
        section != "domain_mail_count" && (

          <SearchField>
            <input
              type="search"
              id="search_field"
              className="search-bar form-control"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              // onChange={(e) => setFilterText(e.target.value)}
              name="search_field"
              placeholder="Search"
            />
          </SearchField>
        )
      }
      <StyledDataTable>
        <DataTable
          className="uspassport-custom-tbl"
          selectableRows={false}
          columns={columns}
          data={Data?.length ? Data : [{}]}
          progressPending={isLoading || isLoadingData}
          fixedHeader={true}
          fixedHeaderScrollHeight="550px"
          noDataComponent="No records found"
          pagination={false}
        // pagination={Data?.length ? true : false}
        />
      </StyledDataTable>
      {!Data?.length && isLoading === false && isLoadingData === false && (
        <p style={{ textAlign: "center", color: "red" }}>
          {"No data available in table"}
        </p>
      )}
    </>
  );
};

export default ReactDataTable;
