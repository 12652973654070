export const domainList = [
  {
    id: 1,
    domainName: "philippines-etd.com",
    imageUrl:
      "https://philippines-etd.com/assets/images/Philippines-landing-card.png",
    mailUrl:
      "https://philippines-etd.com/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-ph.online/",
  },
  {
    id: 2,
    domainName: "aruba-edcard.online",
    imageUrl:
      "https://aruba-edcard.online/assets/img/aruba-landing.png",
    mailUrl:
      "https://aruba-edcard.online/imap_api_unread_mail_count_total.php",
    loginUrl: "https://adminaruba.online/",
  },
  {
    id: 3,
    domainName: "uk-eta.online",
    imageUrl:
      "https://uk-eta.online/images/uk-landing-card.png",
    mailUrl:
      "https://uk-eta.online/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-uk.online/",
  },
  {
    id: 4,
    domainName: "indonesia-ecd.services",
    imageUrl:
      "https://indonesia-ecd.services/assets/img/Indonesia-landing-site.png",
    mailUrl:
      "https://indonesia-ecd.services/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-ind.online/",
  },
  {
    id: 5,
    domainName: "indonesia-evoa.online",
    imageUrl:
      "https://indonesia-evoa.online/uploads/Indonesia-landing-site.png",
    mailUrl:
      "https://indonesia-evoa.online/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-evoa.online/",
  },
  {
    id: 6,
    domainName: "cambodia-evisa.online",
    imageUrl:
      "https://cambodia-evisa.online/assets/images/cambodia-logo.png",
    mailUrl:
      "https://cambodia-evisa.online/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-cambodiaevisa.online/",
  },
  {
    id: 7,
    domainName: "cambodia-e-arrival.com",
    imageUrl:
      "https://cambodia-e-arrival.com/assets/img/cambodia-eArrival-logo.png",
    mailUrl:
      "https://cambodia-e-arrival.com/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-cambodia-e-arrival.online/",
  },
  {
    id: 8,
    domainName: "japan-arrivalcard.com",
    imageUrl:
      "https://japan-arrivalcard.com/assets/img/japan-landing.png",
    mailUrl:
      "https://japan-arrivalcard.com/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-jp.online/",
  },
  {
    id: 9,
    domainName: "newzealand-td.online",
    imageUrl:
      "https://newzealand-td.online/assets/img/new_zealand_landing.png",
    mailUrl:
      "https://newzealand-td.online/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-nz.online/",
  },
  {
    id: 10,
    domainName: "cuba-arrivalcard.online",
    imageUrl:
      "https://cuba-arrivalcard.online/img/cuba-landing-site.png",
    mailUrl:
      "https://cuba-arrivalcard.online/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-cubaarrival.online/",
  },
  {
    id: 11,
    domainName: "malaysia-arrival-card.online",
    imageUrl:
      "https://malaysia-arrival-card.online/img/malaysia-arrival-landing.png",
    mailUrl:
      "https://malaysia-arrival-card.online/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-malaysia-arrival.com/",
  },
  {
    id: 12,
    domainName: "sg-arrivalcard.online",
    imageUrl:
      "https://sg-arrivalcard.online/assets/img/singapore-landing-card.png",
    mailUrl:
      "https://sg-arrivalcard.online/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-sg.online/",
  },
  {
    id: 13,
    domainName: "srilanka-eta.com",
    imageUrl:
      "https://srilanka-eta.com/assets/front/images/sri-lanka-landing-card.png",
    mailUrl:
      "https://srilanka-eta.com/admin/imap_api_unread_mail_count_total.php",
    loginUrl: "https://srilanka-eta.com/admin/",
  },
  {
    id: 14,
    domainName: "estaportal.online",
    imageUrl:
      "https://estaportal.online/assets/images/esta-landing-card.png",
    mailUrl:
      "https://estaportal.online/imap_api_unread_mail_count_total.php",
    loginUrl: "https://estaportal.online/admin/",
  },
  {
    id: 15,
    domainName: "canada-eta.online",
    imageUrl:
      "https://canada-eta.online/images/canada-landing-card.png",
    mailUrl:
      "https://canada-eta.online/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-ca.online",
  },
  {
    id: 16,
    domainName: "eta-uk.site",
    imageUrl:
      "https://uk-eta.online/images/uk-landing-card.png",
    mailUrl:
      "https://eta-uk.site/imap_api_unread_mail_count_total.php",
    loginUrl: "https://admin-uk.online",
  },
];
